import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { JobForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class JobUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Нээлттэй ажлын байр',
            key: shortid.generate(),
            jobName: '',
            goal: '',
            generalRequirement: [],
            jobRequirement: [],
            endDate: ''
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/jobs/${id}`, { progress: true})

            this.setState({
                key: shortid.generate(),
                jobName: data.jobName,
                goal: data.goal,
                generalRequirement: data.generalRequirement,
                jobRequirement: data.jobRequirement,
                endDate: data.endDate
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            jobName,
            goal,
            generalRequirement,
            jobRequirement,
            endDate
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <JobForm
                    key={key}
                    path={`jobs/${id}`}
                    method="put"
                    jobName={jobName}
                    goal={goal}
                    generalRequirement={generalRequirement}
                    jobRequirement={jobRequirement}
                    endDate={endDate}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(JobUpdate)
