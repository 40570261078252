import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { WorkForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class WorkUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Төсөл',
            key: shortid.generate(),
            name: '',
            images: [],
            description: '',
            isFeatured: false,
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/works/${id}`, { progress: true})

            this.setState({
                key: shortid.generate(),
                name: data.name,
                images: data.images,
                description: data.description,
                isFeatured: data.isFeatured
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            description,
            images,
            isFeatured
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <WorkForm
                    key={key}
                    path={`works/${id}`}
                    method="put"
                    name={name}
                    description={description}
                    images={images}
                    isFeatured={isFeatured}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(WorkUpdate)
