import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
} from '@material-ui/core'
import {
    CustomSnackBar
} from '../components'
import axios from '../plugins/axios'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers'

const styles = theme => ({
    marginBottom: {
        margin: '0 0 20px 0'
    }
})
class OtherWorkForm extends Component {

    static defaultProps = {
        body: '',
        date: null
    }

    constructor(props) {
        super(props)

        const {
            body,
            date
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            body,
            date
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            body: '',
            date: null
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true
        })

        const {
            path,
            method
        } = this.props

        const {
            body,
            date
        } = this.state

        try {
            await axios[method](path, {
                body,
                date
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {

        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    dateChange = date => {
        this.setState({ date })
    }

    render() {
        const {
            body,
            date,
            isSnackOpen
        } = this.state

        const { 
            message,
            classes
        } = this.props

        const { marginBottom } = classes

        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <TextField
                        label="Тайлбар"
                        value={body}
                        onChange={this.handleChange('body')}
                        variant="outlined"
                        margin="normal"
                        multiline
                        rows={5}
                        fullWidth
                    />
                    <div className={marginBottom}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                label="Он"
                                value={date}
                                onChange={this.dateChange}
                                views={["year"]}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        Хадгалах
                    </Button>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(OtherWorkForm)
