import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Typography
} from '@material-ui/core'
import {
    Plus,
    Minus
} from 'mdi-material-ui'
import {
    CustomSnackBar,
    BaseDatePicker
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})
class JobForm extends Component {

    static defaultProps = {
        jobName: '',
        goal: '',
        generalRequirement: [''],
        jobRequirement: [''],
        endDate: null
    }

    constructor(props) {
        super(props)

        const {
            jobName,
            goal,
            generalRequirement,
            jobRequirement,
            endDate
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            jobName,
            goal,
            generalRequirement,
            jobRequirement,
            endDate
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            jobName: '',
            goal: '',
            generalRequirement: [''],
            jobRequirement: [''],
            endDate: null
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true
        })

        const {
            path,
            method
        } = this.props

        const {
            jobName,
            goal,
            generalRequirement,
            jobRequirement,
            endDate
        } = this.state

        try {
            await axios[method](path, {
                jobName,
                goal,
                generalRequirement,
                jobRequirement,
                endDate
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {

        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    handleRequire = index => e => {
        const {
            name,
            value
        } = e.target

        let items = [...this.state[name]]
        items[index] = value

        this.setState({
            [name]: items
        })
    }

    addRequirement = stateName => e => {
        this.setState(prevState => ({
            [stateName]: [...prevState[stateName], '']
        }))
    }

    removeRequirement = stateName => e => {
        let copyState = this.state[stateName]
        copyState.pop()
        this.setState({
            [stateName]: copyState
        })
    }

    dateChanged = endDate => {
        this.setState({ endDate })
    }

    render() {
        const {
            jobName,
            goal,
            generalRequirement,
            jobRequirement,
            endDate,
            isSnackOpen,
        } = this.state
        const { message } = this.props
        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <TextField
                        label="Нэр"
                        value={jobName}
                        onChange={this.handleChange('jobName')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Ажлын байрны зорилго"
                        value={goal}
                        onChange={this.handleChange('goal')}
                        variant="outlined"
                        margin="normal"
                        multiline
                        rows={5}
                        fullWidth
                    />
                    <BaseDatePicker
                        value={endDate}
                        onChange={this.dateChanged}
                        label="Дуусах хугацаа"
                    />
                    <div>
                        <Typography variant="body2">
                            Ерөнхий шаардлага
                        </Typography>
                        {
                            generalRequirement.map((value, index) => {
                                return (
                                    <TextField
                                        name='generalRequirement'
                                        key={index}
                                        value={value}
                                        onChange={this.handleRequire(index)}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                )
                            })
                        }
                        <Plus
                            onClick={this.addRequirement('generalRequirement')}
                        />
                        <Minus
                            onClick={this.removeRequirement('generalRequirement')}
                        />
                    </div>
                    <div>
                        <Typography variant="body2">
                            Тавигдах шаардлага
                        </Typography>
                        {
                            jobRequirement.map((value, index) => {
                                return (
                                    <TextField
                                        name="jobRequirement"
                                        key={index}
                                        value={value}
                                        onChange={this.handleRequire(index)}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                )
                            })
                        }
                        <Plus
                            onClick={this.addRequirement('jobRequirement')}
                        />
                        <Minus
                            onClick={this.removeRequirement('jobRequirement')}
                        />
                    </div>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        Хадгалах
                    </Button>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(JobForm)
