import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from '@material-ui/core'
import DropZone from './../components/DropZone'
import { CustomSnackBar } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'G', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

class EmployeeForm extends Component {

    static defaultProps = {
        name: '',
        image: '',
        position: '',
        order: 'A'
    }

    constructor(props) {
        super(props)

        const {
            name,
            position,
            image,
            order
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            name,
            position,
            image,
            order
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            name: '',
            image: '',
            position: '',
            order: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true
        })

        const {
            path,
            method
        } = this.props

        const {
            name,
            position,
            image,
            order
        } = this.state

        try {
            await axios[method](path, {
                name,
                position,
                image,
                order
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {

        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }
    
    onChange = (path = '') => {
        this.setState({
            image: path
        })
    }

    render() {
        const {
            name,
            position,
            image,
            order,
            isSnackOpen
        } = this.state

        const {
            classes,
            message
        } = this.props
        const { formControl } = classes

        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <TextField
                        label="Нэр"
                        value={name}
                        onChange={this.handleChange('name')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Албан тушаал"
                        value={position}
                        onChange={this.handleChange('position')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <FormControl className={formControl}>
                        <InputLabel htmlFor="order">Эрэмбэ</InputLabel>
                        <Select
                            value={order}
                            onChange={this.handleChange('order')}
                            inputProps={{
                                id: 'order',
                            }}
                        >
                            {
                                letters.map(data =>
                                    <MenuItem value={data}>{data}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <DropZone
                        uploadPath="image/upload"
                        onChange={this.onChange}
                        file={image}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        Хадгалах
                    </Button>
                    <CustomSnackBar
                        message={ message }
                        open={ isSnackOpen }
                        snackClose={ this.snackClose }
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(EmployeeForm)
