import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Dashboard } from './../layouts'
import {
    TextField,
    Grid,
    Typography,
    Button
} from '@material-ui/core'
import { CustomSnackBar } from './../components'
import shortid from 'shortid'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class SettingForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Тохиргоо',
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            totalEmployee: '',
            totalProject: '',
            currentProject: '',
            introduction: '',
            email: '',
            number: '',
            address: '',
            facebook: '',
            linkedin: ''
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }


    fetchData = async () => {
        try {

            this.setState({
                key: shortid.generate()
            })

            const { data } = await axios.get(`/option`, { progress: true })

            data.map(item =>
                this.setState({ [item.name]: item.value })
            )

        } catch (e) {
            //
        }
    }

    update = async e => {
        e.preventDefault()
        this.setState({
            disabled: true
        })

        const {
            totalEmployee,
            totalProject,
            currentProject,
            introduction,
            email,
            number,
            address,
            facebook,
            linkedin
        } = this.state

        try {
            await axios.put('/option', {
                totalEmployee,
                totalProject,
                currentProject,
                introduction,
                email,
                number,
                address,
                facebook,
                linkedin
            })

            this.setState({
                isSnackOpen: true,
                disabled: false
            })
        } catch (err) {
            //
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    render() {
        const {
            title,
            key,
            isSnackOpen,
            totalEmployee,
            totalProject,
            currentProject,
            introduction,
            email,
            number,
            address,
            facebook,
            linkedin
        } = this.state
        return (
            <Dashboard title={title}>
                <form
                    key={key}
                    onSubmit={this.update}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                Нүүр хуудас
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Ажилчдын тоо"
                                value={totalEmployee}
                                onChange={this.handleChange('totalEmployee')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Гүйцэтгэсэн төсөл"
                                value={totalProject}
                                onChange={this.handleChange('totalProject')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Идэвхтэй төсөл"
                                value={currentProject}
                                onChange={this.handleChange('currentProject')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                Бидний тухай
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Компани"
                                value={introduction}
                                onChange={this.handleChange('introduction')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                Холбоо барих
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="И-мэйл"
                                value={email}
                                onChange={this.handleChange('email')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Утасны дугаар"
                                value={number}
                                onChange={this.handleChange('number')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Хаяг"
                                value={address}
                                onChange={this.handleChange('address')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                Цахим хаягууд
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Facebook"
                                value={facebook}
                                onChange={this.handleChange('facebook')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Linkedin"
                                value={linkedin}
                                onChange={this.handleChange('linkedin')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        шинэчлэх
                    </Button>
                    <CustomSnackBar
                        message="Амжилттай шинэчлэгдлээ"
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </Dashboard>
        )
    }
}

export default withStyles(styles)(SettingForm)
