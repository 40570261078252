import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { OtherWorkForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class OtherWorkUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Бусад төсөл',
            key: shortid.generate(),
            body: '',
            date: ''
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/work/other/${id}`, { progress: true})

            this.setState({
                key: shortid.generate(),
                body: data.body,
                date: data.date
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            body,
            date
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <OtherWorkForm
                    key={key}
                    path={`work/other/${id}`}
                    method="put"
                    body={body}
                    date={date}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(OtherWorkUpdate)
