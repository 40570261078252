import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Checkbox,
    FormControlLabel
} from '@material-ui/core'
import DropZoneMultiple from './../components/DropZoneMultiple'
import { CustomSnackBar } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginLeft: {
        margin: '0 10px 0 0'
    },
    marginBottom: {
        margin: '0 0 20px 0'
    }
})

class WorkForm extends Component {

    static defaultProps = {
        name: '',
        images: [],
        description: '',
        isFeatured: false,
    }

    constructor(props) {
        super(props)

        const {
            name,
            description,
            images,
            isFeatured
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            name,
            description,
            images,
            isFeatured
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            name: '',
            images: [],
            description: '',
            isFeatured: false
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true
        })

        const {
            path,
            method
        } = this.props

        const {
            name,
            description,
            images,
            isFeatured
        } = this.state

        try {
            await axios[method](path, {
                name,
                description,
                images,
                isFeatured
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {

        }
    }

    selectedValueChange = e => {
        this.setState({
            isFeatured: e.target.checked
        })
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    onChange = (path, bool) => {
        let copyImages = []
        if (bool === undefined) {
            this.setState(prevState => ({
                images: [...prevState.images, path]
            }))
        } else {
            copyImages = this.state.images.filter(val =>
                val !== path
            )
            this.setState({
                images: copyImages
            })
        }
    }

    render() {
        const {
            name,
            description,
            images,
            isFeatured,
            isSnackOpen
        } = this.state

        const {
            classes,
            message
        } = this.props
        const {
            marginLeft,
            marginBottom
        } = classes

        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <TextField
                        label="Нэр"
                        value={name}
                        onChange={this.handleChange('name')}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        label="Тайлбар"
                        value={description}
                        onChange={this.handleChange('description')}
                        variant="outlined"
                        margin="normal"
                        multiline
                        rows={5}
                        fullWidth
                    />
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={marginLeft}
                                    checked={isFeatured}
                                    onChange={this.selectedValueChange}
                                    color="primary"
                                />
                            }
                            label="Нүүр хуудсанд харуулах"
                        />
                    </div>
                    <div className={marginBottom}>
                        <DropZoneMultiple
                            uploadPath="image/upload"
                            onChange={this.onChange}
                            files={images}
                        />
                    </div>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        Хадгалах
                    </Button>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(WorkForm)
