import React from 'react'

export default (props) => {
    const { value } = props

    return (
        <span>
            {value}
        </span>
    )
}