import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Dashboard } from './../layouts'
import {
    BaseTable,
    BaseTableActions,
    ActionUpdateButton
} from './../components'
import { pathJoin } from './../utils/helpers'

const styles = theme => ({
    //
})

class Posts extends Component {
    constructor(props) {
        super(props)

        this.table = React.createRef()

        this.state = {
            title: 'Мэдээ',
            columns: [
                {
                    name: 'name',
                    title: 'Нэр'
                },
                {
                    name: 'description',
                    title: 'Товч тайлбар'
                },
                {
                    name: 'created',
                    title: 'Үүсгэсэн'
                },
                {
                    name: 'updated',
                    title: 'Шинэчилсэн'
                },
                {
                    getCellValue: ({ id }) => <ActionUpdateButton path={pathJoin('posts', id)} />
                }
            ]
        }
    }

    onDelete = async () => {
        return await this.table.current.fetchData()
    }

    render() {
        const {
            title,
            columns
        } = this.state

        return (
            <Dashboard title={title}>
                <BaseTableActions
                    newPath="/posts/new"
                    deletePath="/posts/mass/destroy"
                    onDelete={this.onDelete}
                />
                <BaseTable
                    path="posts"
                    columns={columns}
                    innerRef={this.table}
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(Posts)
