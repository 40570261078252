import React from 'react'
import {
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom'
import {
    ForgotPassword,
    Home,
    Login,
    NotFound,
    PasswordReset,
    Register,
    UserUpdate,
    UserNew,
    Users,
    WorkUpdate,
    WorkNew,
    Works,
    EmployeeUpdate,
    EmployeeNew,
    Employees,
    ReviewUpdate,
    ReviewNew,
    Reviews,
    PostUpdate,
    PostNew,
    Posts,
    JobUpdate,
    JobNew,
    Jobs,
    Resumes,
    OtherWorkUpdate,
    OtherWorkNew,
    OtherWorks,
    Requests
} from './pages'

import {
    ResumeForm,
    SettingForm,
    RequestForm
} from './forms'

export default () => (
    <Router>
        <Switch>
            <Route
                path="/signin"
                component={Login}
            />
            <Route
                path="/signup"
                component={Register}
            />
            <Route
                path="/forgot/password"
                component={ForgotPassword}
            />
            <Route
                path="/password/reset/:token"
                component={PasswordReset}
            />
            <Route
                path="/"
                component={Home}
                exact
            />
            <Route
                path="/users/new"
                component={UserNew}
            />
            <Route
                path="/users/:id"
                component={UserUpdate}
            />
            <Route
                path="/users"
                component={Users}
            />
            <Route
                path="/works/new"
                component={WorkNew}
            />
            <Route
                path="/works/:id"
                component={WorkUpdate}
            />
            <Route
                path="/works"
                component={Works}
            />
            <Route
                path="/employees/new"
                component={EmployeeNew}
            />
            <Route
                path="/employees/:id"
                component={EmployeeUpdate}
            />
            <Route
                path="/employees"
                component={Employees}
            />
            <Route
                path="/reviews/new"
                component={ReviewNew}
            />
            <Route
                path="/reviews/:id"
                component={ReviewUpdate}
            />
            <Route 
                path="/reviews"
                component={Reviews}
            />
            <Route 
                path="/posts/new"
                component={PostNew}
            />
            <Route 
                path="/posts/:id"
                component={PostUpdate}
            />
            <Route 
                path="/posts"
                component={Posts}
            />
            <Route 
                path="/jobs/new"
                component={JobNew}
            />
            <Route 
                path="/jobs/:id"
                component={JobUpdate}
            />
            <Route 
                path="/jobs"
                component={Jobs}
            />
            <Route 
                path="/other/new"
                component={OtherWorkNew}
            />
            <Route 
                path="/other/:id"
                component={OtherWorkUpdate}
            />
            <Route 
                path="/other"
                component={OtherWorks}
            />
            <Route 
                path="/requests/:id"
                component={RequestForm}
            />
            <Route 
                path="/requests"
                component={Requests}
            />
            <Route 
                path="/resumes/:id"
                component={ResumeForm}
            />
            <Route 
                path="/resumes"
                component={Resumes}
            />
            <Route 
                path="/settings"
                component={SettingForm}
            />
            <Route component={NotFound} />
        </Switch>
    </Router>
)
